import { render, staticRenderFns } from "./AgencyCreateNeed.vue?vue&type=template&id=64f78fd7&scoped=true&"
import script from "./AgencyCreateNeed.vue?vue&type=script&lang=js&"
export * from "./AgencyCreateNeed.vue?vue&type=script&lang=js&"
import style0 from "./AgencyCreateNeed.vue?vue&type=style&index=0&id=64f78fd7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f78fd7",
  null
  
)

export default component.exports